import { ClassNameMap, Box, Divider } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { TokenDetailsState } from "../../../features/ui/uiSlice";
import { hasCharacters } from "../../../lib/funclib";
import { DefinitionState } from "../../../lib/types";
import Actions from "./Actions";
import Definitions from "./Definitions";
import Infos from "./Infos";
import Substrings from "./Substrings";
import Synonyms from "./Synonyms";
import DiscoverableWord from "../../DiscoverableWord";
import { useTranslate } from "ra-core";

type Props = {
  tokenDetails: TokenDetailsState;
  definition: DefinitionState;
  otherDefinitions: DefinitionState[];
  classes: ClassNameMap<string>;
};

export default function DefContainer({ tokenDetails, definition, classes, otherDefinitions }: Props): ReactElement {
  const fromLang = useAppSelector((state) => state.userData.user.fromLang);
  const [lemmaDef, setLemmaDef] = useState<DefinitionState>();
  const translate = useTranslate();
  useEffect(() => {
    const ld = otherDefinitions.filter((x) => tokenDetails.token.l === x.graph)[0];
    if (ld) {
      setLemmaDef(ld);
    }
  }, [otherDefinitions]);
  return (
    <Box className={classes.container}>
      <Infos definition={lemmaDef || definition} tokenDetails={tokenDetails} />
      <Synonyms token={tokenDetails.token} definition={definition} />
      <Divider />
      <Actions className={classes.actions} tokenDetails={tokenDetails} definition={definition} />
      <Definitions definition={definition} classes={classes} />
      {lemmaDef && tokenDetails.token.w && (
        <>
          <Divider />
          <Box>
            <span>{translate("widgets.popup.headword")}: </span>
            <DiscoverableWord graph={tokenDetails.token.l} newTab />
          </Box>
          <Definitions definition={lemmaDef} classes={classes} />
        </>
      )}
      {hasCharacters(fromLang) && tokenDetails.token.l.length > 1 && (
        <>
          <Divider /> <Substrings token={tokenDetails.token} fromLang={fromLang} />
        </>
      )}
    </Box>
  );
}
